.accounts-details {
  padding: 0px;
}
.accounts-details-header {
  padding: 16px 24px;
  border-bottom: 1px solid rgba(34,36,38,0.15);
}
.accounts-details-header .accounts-details-title {
  color: #161d39;
  margin-bottom: 0px;
}
.accounts-details-header >h2 {
  margin-top: 10px;
}
.accounts-details-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px !important;
}
.accounts-details-list-wrapper .list-name {
  color: #161d39;
  font-weight: bold;
}
.accounts-details-list-wrapper .amount {
  font-size: 14px;
}
.accounts-index {
  padding-top: 23px;
}
.accounts-index .tabs {
  margin-bottom: 32px;
  border-bottom: 1px solid #cfd4e9;
  padding-left: 68px;
  display: flex;
  align-items: normal;
  flex-direction: row;
}
.accounts-index .tabs .tab {
  float: left;
  margin: 0 30px;
  padding: 0 4px;
  padding-bottom: 12px;
  cursor: pointer;
  color: #161d39;
}
.accounts-index .tabs .tab span {
  color: #737996;
}
.accounts-index .tabs .tab:first-child {
  margin-left: 0;
}
.accounts-index .tabs .tab.active {
  border-bottom: 3px solid #4361d8;
  font-size: 16px;
  font-weight: 700;
}
.accounts-index .accounts-tab .ui.grid {
  margin-right: 0;
}
.accounts-index .aggregate {
  padding-left: 68px;
  padding-right: 68px;
}
.accounts-index .aggregate .title {
  font-size: 28px;
  line-height: 34px;
  padding-bottom: 13px;
}
.accounts-index .aggregate table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.accounts-index .aggregate table tbody tr:first-child {
  background: #f7f9fd;
}
.accounts-index .aggregate table tbody tr:first-child td {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
}
.accounts-index .aggregate table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
  text-transform: capitalize !important;
}
.accounts-index .aggregate table td {
  font-size: 14px;
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.accounts-index .aggregate table td.symbol {
  display: flex;
  align-items: center;
}
.accounts-index .aggregate table td.symbol i {
  font-size: 32px;
}
.accounts-index .aggregate table td:last-child {
  font-weight: 700;
}
.accounts-index .balances {
  padding-left: 68px;
  padding-right: 68px;
}
.accounts-index .balances .title {
  font-size: 28px;
  float: left;
  line-height: 34px;
  padding-bottom: 13px;
}
.accounts-index .balances .controls {
  float: right;
  margin-bottom: 24px;
}
.accounts-index .balances .controls .ui.selection.dropdown {
  border: 2px solid #cfd4e9;
  min-width: 233px;
}
.accounts-index .balances .controls div {
  margin-right: 16px;
}
.accounts-index .balances table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.accounts-index .balances table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.accounts-index .balances table td {
  font-size: 15px;
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.accounts-index .balances table td.symbol {
  display: flex;
  align-items: center;
}
.accounts-index .balances table td.symbol i {
  font-size: 32px;
}
.accounts-index .balances table td:last-child {
  padding-right: 0;
  padding-left: 0;
}
.accounts-index .ledger {
  padding-left: 68px;
  padding-right: 68px;
}
.accounts-index .ledger .path {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 29px;
}
.accounts-index .ledger .path .name {
  color: #737996;
  margin-right: 14px;
}
.accounts-index .ledger .path .currency {
  font-weight: 700;
  margin-left: 10px;
}
.accounts-index .ledger .widgets {
  display: flex;
  flex-direction: row;
  margin-bottom: 46px;
}
.accounts-index .ledger .widgets .block {
  background: #fff;
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 4px;
  filter: drop-shadow(0px 5px 10px rgba(0,0,0,0.05));
  width: 225px;
  height: 110px;
  padding: 29px;
  margin-right: 16px;
  cursor: pointer;
}
.accounts-index .ledger .widgets .block.active {
  background: #4361d8;
  border: 1px solid #2a45b1;
  filter: drop-shadow(0px 10px 20px rgba(0,25,125,0.3));
}
.accounts-index .ledger .widgets .block.active .blockTitle {
  color: #c5d0ff;
}
.accounts-index .ledger .widgets .block.active .blockValue {
  color: #fff;
}
.accounts-index .ledger .widgets .block .blockTitle {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  color: #737996;
  font-weight: 700;
  margin-bottom: 9px;
}
.accounts-index .ledger .widgets .block .blockValue {
  font-size: 24px;
  line-height: 29px;
}
.accounts-index .ledger .title {
  font-size: 28px;
  float: left;
  line-height: 34px;
}
.accounts-index .ledger .controls {
  float: right;
  margin-bottom: 24px;
}
.accounts-index .ledger .controls .ui.selection.dropdown {
  border: 2px solid #cfd4e9;
  min-width: 233px;
}
.accounts-index .ledger .controls div {
  margin-right: 16px;
}
.accounts-index .ledger table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.accounts-index .ledger table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.accounts-index .ledger table td {
  font-size: 15px;
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.accounts-index .ledger table td.symbol {
  display: flex;
  align-items: center;
}
.accounts-index .ledger table td.symbol i {
  font-size: 32px;
}
.accounts-index .ledger table td:last-child {
  padding-right: 0;
  padding-left: 0;
}
.accounts-index .Accounts-block {
  float: left;
  clear: left;
  padding-bottom: 40px;
  padding-left: 68px;
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
}
.accounts-index .Accounts-block .value {
  font-size: 24px;
  font-weight: bold;
  padding: 8px 0;
}
.accounts-index .DropdownWrapper {
  float: left;
  clear: left;
  padding-left: 68px;
  width: 322px !important;
}
.accounts-index .header .title {
  font-size: 28px;
  padding-bottom: 34px;
  text-transform: capitalize;
}
.accounts-index .titleBlock .title {
  text-transform: capitalize;
  color: #737996;
}
.Accounts-row {
  float: left;
  clear: left;
  padding-left: 68px;
  margin-top: 40px;
}
.Accounts-row .Accounts-block {
  padding-left: 0;
  padding-bottom: 0 !important;
}
.Tabs {
  margin-bottom: 32px;
  float: left;
  border-bottom: 1px solid #cfd4e9;
  padding-left: 68px;
  width: 100%;
}
.Tabs .Tab {
  float: left;
  margin: 0 40px;
  padding: 0 4px;
  padding-bottom: 12px;
  cursor: pointer;
  color: #161d39;
}
.Tabs .Tab:first-child {
  margin-left: 0;
}
.Tabs .Tab.is--selected {
  border-bottom: 3px solid #4361d8;
  font-size: 16px;
  font-weight: 700;
}
.Accounts-block .value {
  font-size: 24px;
  font-weight: bold;
  padding: 8px 0;
}
.exchange-details-wrapper {
  padding-bottom: 40px;
  padding: 0px 68px;
}
.exchange-blocks-wrapper {
  margin: 40px 0px;
  display: flex;
}
.exchange-search-sort-wrapper {
  margin: 40px 0px;
  display: flex;
  clear: left;
  align-items: center;
  justify-content: space-between;
}
.exchange-search-sort-wrapper :first-child {
  display: flex;
}
.exchange-search-sort-wrapper :first-child >div {
  margin-right: 10px;
}
.exchange-search-sort-wrapper :last-child {
  display: flex;
  align-items: center;
}
.exchange-search-sort-wrapper :last-child >span {
  margin-right: 10px;
}
.exchange-details-wrapper .accounts-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.exchange-details-wrapper .accounts-wrapper > * {
  margin-right: 15px;
}
.exposures .ui.table.very.basic thead tr th {
  border-top: 1px solid rgba(34,36,38,0.1);
  color: #737996;
}
.exposures .ui.table.very.basic thead {
  font-weight: 300;
}
.exposures .ui.table.very.basic thead tr th {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.exposures .ui.table.very.basic thead tr th:first-child {
  padding-left: 0px !important;
}
.exposures .ui.table.very.basic td {
  padding: 16px 24px;
}
.exposures .icon-arrow-right {
  position: relative;
  top: 2px;
  left: 6px;
}
.Dashboard-block .titleBlock {
  padding: 16px 24px;
}
.Dashboard-block .exposures-block-table {
  clear: left;
  width: 100%;
  text-align: left;
}
.Dashboard-block .exposures-block-table th {
  font-size: 13px;
  color: #737996;
  font-weight: 300;
  padding-top: 0 !important;
  padding-bottom: 9px !important;
}
.Dashboard-block .exposures-block-table th,
.Dashboard-block .exposures-block-table td {
  border-bottom: 1px solid #cfd4e9;
  padding: 16px 24px;
}
.Dashboard-block .exposures-block-table tr:last-child td {
  border-bottom: 0;
}
.Dashboard-block .color-bar-wrapper {
  margin-top: 10px;
  padding: 10px 24px;
}
.Dashboard-block .color-bar-wrapper >div:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.Dashboard-block .color-bar-wrapper >div:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.Dashboard-block .color-bar {
  display: inline-block;
}
.col-0 {
  font-weight: bold;
}
.correlationsTable .label,
.correlationsTable .value {
  float: left;
}
.correlationsTable .value {
  width: 47.19px;
  height: 31.88px;
  text-align: center;
  padding: 5px;
}
.correlationsTable .label {
  width: 47.19px;
  height: 31.88px;
  padding: 5px;
  text-align: center;
}
.correlationsTable .row {
  float: left;
  clear: left;
}
.correlationsTable > .row {
  margin-left: 47.19px;
}
.CorrelationsWidget {
  max-width: 60%;
}
.CorrelationsWidget .ui.grid {
  margin: 0;
}
.CorrelationsWidget header {
  margin-left: 62.19px;
  padding-bottom: 0;
}
.CorrelationsWidget header .label,
.CorrelationsWidget section .label,
.CorrelationsWidget header .value,
.CorrelationsWidget section .value {
  float: left;
}
.CorrelationsWidget header .value,
.CorrelationsWidget section .value {
  width: 47.19px;
  height: 31.88px;
  text-align: center;
  padding: 5px;
}
.CorrelationsWidget header .label,
.CorrelationsWidget section .label {
  width: 47.19px;
  height: 31.88px;
  padding: 5px;
  text-align: center;
}
.ui.form .fields {
  clear: left;
}
.ui.form label {
  text-transform: capitalize !important;
}
.ui.grid.counterparty-page .list-view {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #cfd4e9;
  height: 100vh;
  min-width: 302px;
}
.ui.grid.counterparty-page .list-view button {
  width: 224px;
}
.ui.grid.counterparty-page .list-view .ui.grid .full-list {
  padding-left: 41px;
  padding-right: 36px;
  padding-top: 0;
  margin-top: 0;
  min-height: 100px;
}
.ui.grid.counterparty-page .list-view .list-item {
  margin-top: 0;
  border-bottom: 1px solid #cfd4e9;
  padding-top: 22px;
  padding-bottom: 24px;
  padding-left: 20px;
  font-weight: 400;
  color: #737996;
  cursor: pointer;
}
.ui.grid.counterparty-page .list-view .list-item:hover {
  background: #f7f9fd;
}
.ui.grid.counterparty-page .list-view .list-item.active {
  background: #f7f9fd;
}
.ui.grid.counterparty-page .detail-view {
  max-height: 100vh;
  overflow-y: auto;
  flex: 1 0;
}
.ui.grid.counterparty-page .detail-view .counterparty-fields {
  margin-top: 42px !important;
}
.ui.grid.counterparty-page .detail-view .grid {
  padding-left: 40px;
  padding-right: 30px;
}
.ui.grid.counterparty-page .detail-view label {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  color: #161d39;
}
.ui.grid.counterparty-page .detail-view span {
  display: block;
  margin-top: 24px;
  color: #737996;
}
.ui.grid.counterparty-page .detail-view .title {
  font-size: 27px;
  padding: 8px 0;
  line-height: 32px;
  text-transform: capitalize;
}
.ui.grid.counterparty-page .detail-view .ui.basic.button,
.ui.grid.counterparty-page .detail-view .ui.basic.buttons .button {
  background: transparent;
  color: #737996 !important;
  font-weight: 400;
  font-family: Lato;
  font-style: normal;
  height: 44px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 6px;
  border: 2px solid #cfd4e9;
  box-sizing: border-box;
  width: 110px;
}
.ui.grid.counterparty-page .detail-view .wire-details {
  margin-top: 19px;
  border-bottom: 1px solid #dfe2e5;
  box-sizing: border-box;
  border-radius: 4px;
}
.ui.grid.counterparty-page .detail-view .wire-details .title {
  padding-left: 14px;
  font-size: 17px;
  text-transform: none;
}
.ui.grid.counterparty-page .detail-view .wire-details i {
  color: #0ead6a;
  font-size: 12px;
}
.ui.grid.counterparty-page .detail-view .wire-details span {
  margin-top: 0;
  font-size: 15px;
  padding-left: 14px;
  line-height: 25px;
}
.ui.grid.counterparty-page .detail-view .wire-details .verified {
  color: #0ead6a;
  font-size: 12px;
}
.ui.grid.counterparty-page .detail-view .wire-details .memo {
  font-size: 12px;
}
.ui.grid.counterparty-page .detail-view .wire-details .dropdown {
  color: #477ba1;
  padding-left: 20px;
}
.ui.grid.counterparty-page .detail-view .wire-details .dropdown i {
  padding-left: 0;
}
.ui.grid.counterparty-page .detail-view .wire-details .dropdown b {
  color: #161d39;
}
.ui.grid.counterparty-page .detail-view .wire-details .tooltip .tooltiptext::after {
  top: -20%;
}
.ui.popup .ui.list .item {
  cursor: pointer;
}
.ui.modal.delete .actions .ui.button {
  width: 150px;
}
.ui.modal.delete .actions .ui.button:first-child {
  background: transparent;
  color: #737996;
  font-weight: 400;
  font-family: Lato;
  font-style: normal;
  height: 44px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 6px;
  border: 2px solid #cfd4e9;
  box-sizing: border-box;
}
.column .ui.input.error {
  color: #dc2647;
  margin-bottom: 5px;
}
.column .ui.input.error>input {
  background: #fff;
  border-color: #dc2647;
  color: #dc2647;
}
.error-text {
  color: #dc2647;
  margin-top: 5px;
}
.ui.FormModal.modal>.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #a6b2c4;
}
textarea {
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255,255,255,0);
  padding: 0.78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,0.15);
  outline: 0;
  color: rgba(0,0,0,0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
}
textarea:focus {
  color: rgba(0,0,0,0.95);
  border-color: #85b7d9;
  border-radius: 0.28571429rem;
  background: #fff;
  -webkit-box-shadow: 0 0 0 0 #222403 inset;
  box-shadow: 0 0 0 0 #222403 inset;
  -webkit-appearance: none;
}
textarea:focus::placeholder {
  opacity: 1;
}
textarea::placeholder {
  opacity: 0.5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.dashboard {
  padding-top: 23px;
  padding-left: 68px;
  padding-right: 61px;
}
.dashboard .ui.grid .row {
  align-items: flex-start;
}
.dashboard .ui.grid .row .exposure-widgets {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dashboard .ui.grid .row .exposure-widgets .block-box {
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 14px;
  width: 374px;
}
.dashboard .ui.grid .row .exposure-widgets .block-box:last-child {
  margin-right: 0;
}
.dashboard .ui.grid .row .exposure-widgets .block-box .titleBlock {
  padding-left: 28px;
  margin-top: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}
.dashboard .ui.grid .row .exposure-widgets .block-box .titleBlock .title {
  padding-bottom: 8px;
  text-transform: uppercase;
  color: #737996;
}
.dashboard .ui.grid .row .exposure-widgets .block-box .titleBlock .value {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  padding-bottom: 14px;
}
.dashboard .ui.grid .row .exposure-widgets .block-box .titleBlock .change-positive {
  color: #0ead6a;
}
.dashboard .ui.grid .row .exposure-widgets .block-box .titleBlock .change-negative {
  color: #dc2647;
}
.dashboard .Dashboard-block .icon {
  margin-right: 6px;
}
.dashboard .ui.grid {
  margin-right: 0;
}
.PerformanceWidget {
  padding-top: 24px;
  min-height: 549px;
}
.OrganizationsBlock {
  width: 100%;
  margin-right: 16px;
}
.OrganizationsBlock .chgValue {
  font-size: 12px;
  float: right;
}
.OrganizationsBlock .headerBlock .valuesRow .value {
  font-size: 30px;
}
.OrganizationsBlock .headerBlock tr td {
  padding: 16px 24px;
  padding-bottom: 0;
  font-weight: bold;
}
.OrganizationsBlock .headerBlock tr .chgValue {
  float: right;
  clear: left;
  font-weight: bold;
  line-height: 14px;
  position: relative;
  top: -12px;
}
.OrganizationsBlock .label {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #737996;
  float: left;
  margin-top: 4px;
}
.OrganizationsBlock .headerBlock .valuesRow .value,
.OrganizationsBlock .rowBlock .valuesRow .value {
  font-weight: bold;
  margin-right: 8px;
  float: left;
}
.OrganizationsBlock .headerBlock tr:last-child td,
.OrganizationsBlock .rowBlock tr:last-child td {
  border-bottom: 1px solid #cfd4e9;
  padding-bottom: 16px;
  padding-top: 0;
}
.OrganizationsBlock .headerBlock tr td:last-child,
.OrganizationsBlock .rowBlock tr td:last-child {
  padding-left: 0;
}
.OrganizationsBlock .headerBlock tr td span.label,
.OrganizationsBlock .rowBlock tr td span.label {
  float: left;
}
.OrganizationsBlock .headerBlock tr td span.label:last-child,
.OrganizationsBlock .rowBlock tr td span.label:last-child {
  clear: left;
  margin-top: 8px;
}
.OrganizationsBlock .rowBlock .value {
  font-size: 20px;
}
.OrganizationsBlock .rowBlock tr:first-child td {
  padding-top: 24px;
  padding-bottom: 10px;
}
.OrganizationsBlock .rowBlock tr td {
  padding-left: 24px;
  padding-right: 24px;
}
.OrganizationsBlock .rowBlock:last-child tr td {
  border-bottom: none;
}
.block-box {
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 32px;
  margin-bottom: 16px;
}
.change-positive {
  color: #0ead6a;
}
.change-negative {
  color: #dc2647;
}
.exposure-index {
  padding-top: 23px;
}
.exposure-index .exposure-tab .ui.grid {
  margin-right: 0;
}
.exposure-index .exposure-positions {
  padding-left: 68px;
  padding-right: 68px;
}
.exposure-index .exposure-positions .title {
  font-weight: 400;
  font-size: 28px;
  float: left;
  margin-top: 14px;
}
.exposure-index .exposure-positions .controls {
  float: right;
  margin-bottom: 17px;
}
.exposure-index .exposure-positions .controls .ui.selection.dropdown {
  border: 2px solid #cfd4e9;
  min-width: 197px;
}
.exposure-index .exposure-positions .controls div {
  margin-right: 16px;
}
.exposure-index .exposure-positions table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.exposure-index .exposure-positions table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.exposure-index .exposure-positions table td {
  font-size: 15px;
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.exposure-index .exposure-positions table td:last-child {
  padding-right: 0;
  font-weight: 700;
}
.exposure-index .exposure-positions table td:first-child span {
  font-size: 12px;
  line-height: 18px;
  color: #737996;
}
.exposure-index .exposure-liquidation {
  padding-left: 68px;
  padding-right: 68px;
}
.exposure-index .exposure-liquidation .title {
  font-weight: 400;
  font-size: 28px;
  float: left;
  margin-top: 14px;
}
.exposure-index .exposure-liquidation .controls {
  float: right;
  margin-bottom: 17px;
}
.exposure-index .exposure-liquidation .controls .ui.selection.dropdown {
  border: 2px solid #cfd4e9;
  min-width: 197px;
}
.exposure-index .exposure-liquidation .controls div {
  margin-right: 16px;
}
.exposure-index .exposure-liquidation table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.exposure-index .exposure-liquidation table .ellipsis {
  color: #c2c6da;
  visibility: hidden;
}
.exposure-index .exposure-liquidation table tr.liquidated .riskLevel {
  color: #fff;
  background: #dc2647;
}
.exposure-index .exposure-liquidation table tr.critical .riskLevel {
  color: #fff;
  background: #dc2647;
}
.exposure-index .exposure-liquidation table tr.high-risk .riskLevel {
  color: #dc2647;
  background: #fbe9ed;
}
.exposure-index .exposure-liquidation table tr.at-risk .riskLevel {
  color: #dfb32b;
  background: #fcf4db;
}
.exposure-index .exposure-liquidation table tr:hover .ellipsis {
  visibility: visible;
}
.exposure-index .exposure-liquidation table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.exposure-index .exposure-liquidation table td {
  font-size: 15px;
}
.exposure-index .exposure-liquidation table td .tooltiptext {
  margin-top: 8px !important;
  margin-left: -50px !important;
}
.exposure-index .exposure-liquidation table td .tooltiptext::after {
  top: -60%;
  margin-left: -15px;
}
.exposure-index .exposure-liquidation table td .riskLevel {
  border-radius: 12px;
  padding: 0px 16px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  float: left;
  text-align: center;
  font-size: 11px;
  display: inline-table;
}
.exposure-index .exposure-liquidation table td:last-child {
  padding-right: 0;
}
.exposure-index .exposure-liquidation table td:first-child span {
  font-size: 12px;
  line-height: 18px;
  color: #737996;
}
.exposure-index .exposure-details {
  padding-left: 68px;
  padding-right: 47px;
}
.exposure-index .exposure-details .exposure-asset-table {
  width: 100%;
}
.exposure-index .exposure-details .exposure-asset-table .title {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 28px;
  clear: left;
}
.exposure-index .exposure-details .exposure-asset-table table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.exposure-index .exposure-details .exposure-asset-table table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.exposure-index .exposure-details .exposure-asset-table table td {
  font-size: 15px;
}
.exposure-index .exposure-details .exposure-asset-table table td:last-child {
  padding-right: 0;
}
.exposure-index .exposure-details .exposure-asset-table .top-info-wrapper {
  width: 100%;
  padding: 32px 0;
  float: left;
}
.exposure-index .exposure-details .exposure-asset-table .top-info-wrapper .top-info-block {
  padding: 28px;
  width: 200px;
  border: 1px solid #cfd4e9;
  margin-right: 18px;
  border-radius: 4px;
  float: left;
}
.exposure-index .exposure-details .exposure-asset-table .top-info-wrapper .top-info-block .label {
  color: #737996;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.exposure-index .exposure-details .exposure-asset-table .top-info-wrapper .top-info-block .value {
  font-size: 24px;
}
.exposure-index .exposure-details .exposure-widgets {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.exposure-index .exposure-details .exposure-widgets .block-box {
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 14px;
  width: 374px;
}
.exposure-index .exposure-details .exposure-widgets .block-box:last-child {
  margin-right: 0;
}
.exposure-index .exposure-details .exposure-widgets .block-box .titleBlock {
  padding-left: 28px;
  margin-top: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}
.exposure-index .exposure-details .exposure-widgets .block-box .titleBlock .title {
  padding-bottom: 8px;
  text-transform: uppercase;
  color: #737996;
}
.exposure-index .exposure-details .exposure-widgets .block-box .titleBlock .value {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  padding-bottom: 14px;
}
.exposure-index .exposure-details .exposure-widgets .block-box .titleBlock .change-positive {
  color: #0ead6a;
}
.exposure-index .exposure-details .exposure-widgets .block-box .titleBlock .change-negative {
  color: #dc2647;
}
.exposure-index .error,
.exposure-index .warning {
  border-radius: 4px;
  padding: 14px;
  min-width: 513px;
  color: #fff;
  margin-right: 14px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.exposure-index .error i,
.exposure-index .warning i {
  font-size: 24px;
  margin-right: 18px;
}
.exposure-index .error {
  background: #dc2647;
}
.exposure-index .warning {
  background: #f2c94c;
  color: #443812;
}
.exposure-index .warning span {
  margin-left: auto;
  color: #443812;
  text-decoration-line: underline;
  cursor: pointer;
  line-height: 22px;
}
.exposure-index .exposure-block {
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 14px;
  width: 513px;
}
.exposure-index .exposure-block .titleBlock {
  padding: 16px 24px 0 24px;
  font-size: 18px;
  margin-bottom: 11px;
}
.exposure-index .exposure-block .color-bar-wrapper {
  margin-top: 10px;
  padding: 10px 24px;
}
.exposure-index .exposure-block .color-bar-wrapper >div:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.exposure-index .exposure-block .color-bar-wrapper >div:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.exposure-index .exposure-block .color-bar {
  display: inline-block;
}
.exposure-index .exposure-block .col-4 {
  font-weight: 700;
}
.exposure-index .exposure-block .ui.table tr td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.exposure-index .exposure-block .ui.basic.table tbody tr td:first-child {
  padding-left: 24px;
}
.exposure-index .exposure-block table thead tr th {
  color: #737996;
  font-size: 13px;
  font-weight: 400;
}
.issue-modal header {
  margin-top: 33px;
  padding-bottom: 26px;
  margin-left: 107px;
  margin-right: 118px;
  font-size: 27px;
  font-weight: 400;
}
.ui.issue-modal.modal>.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #a6b2c4;
}
.AggregateTab {
  padding: 8px 68px;
  display: flex;
  flex-direction: column;
}
.AggregateTab span .value {
  margin-left: 9px;
}
.exposures-blocks-wrapper {
  margin: 40px 0px;
  display: flex;
}
.per-asset-exposures-wrapper {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.asset-exposures-title {
  font-weight: 400;
  font-size: 28px;
}
.assets-exposures-wrapper {
  border-bottom: 1px solid #cfd4e9;
  border-top: 1px solid #cfd4e9;
  box-sizing: border-box;
  float: left;
  margin-bottom: 16px;
  margin-top: 20px;
}
.ui.search .prompt {
  border-radius: 5px !important;
}
.LineChart svg {
  width: 100%;
}
.LineChart [class*='line'] {
  fill: none;
  stroke-width: 2px;
  display: block;
  stroke-width: 2px;
}
.LineChart .line0,
.LineChart .dot0 {
  stroke: #4361d8;
}
.LineChart .dot0:last-child {
  fill: #4361d8;
}
.LineChart .line1,
.LineChart .dot1 {
  stroke: #ff808b;
}
.LineChart .dot1:last-child {
  fill: #ff808b;
}
.LineChart [class*='dot'] {
  fill: #fff;
  stroke-width: 2px;
}
.LineChart .tick text {
  fill: #737996;
}
.LineChart .tick line {
  stroke: #cfd4e9;
}
.LineChart .domain {
  stroke: #cfd4e9;
}
.LineChart .grid .tick:nth-child(even) {
  stroke-dasharray: 4 4;
}
.LineChart .legend {
  padding: 16px 24px;
}
.LineChart .legend .marker0 {
  background: #4361d8;
  border: 3px solid #4361d8;
}
.LineChart .legend .marker1 {
  background: #ff808b;
  border: 3px solid #ff808b;
}
.LineChart .legend th {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2.78571px;
  padding-right: 32px;
  padding-bottom: 6px;
}
.LineChart .legend th > span:first-child {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: block;
  margin: 4px;
  margin-right: 8px;
}
.LineChart .legend th span {
  float: left;
}
.LineChart .legend .label {
  color: #737996;
}
.LineChart .legend td .value {
  font-size: 20px;
  float: left;
}
.LineChart .legend td .chg {
  border-radius: 4px;
  margin-left: 8px;
  padding: 4px 6px;
  font-size: 12px;
}
.LineChart .legend td .chg.positive {
  color: #0ead6a;
  background: #e7f7f0;
}
.LineChart .legend td .chg.negative {
  color: #dc2647;
  background: #fbe9ed;
}
.critical .alertTag {
  color: #fff;
  background: #dc2647;
}
.high .alertTag {
  color: #dc2647;
  background: #fbe9ed;
}
.medium .alertTag {
  color: #dfb32b;
  background: #fcf4db;
}
.alertTag {
  border-radius: 12px;
  padding: 0px 16px;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  float: left;
  text-align: center;
  font-size: 12px;
  display: inline-table;
}
.LoginWrapper {
  position: fixed;
  left: 0;
  width: 100%;
}
@media only screen and (max-width: 1000px) {
  .Login {
    width: 90% !important;
  }
}
.Login {
  margin: 64px auto;
  width: 25%;
}
.Login .icon-osf {
  font-size: 140px;
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 58px;
}
.Login .footer {
  position: fixed;
  bottom: 2.29%;
  text-align: center;
  color: #737996;
  width: inherit;
}
.Login .footer .login-divider {
  border: 1px solid #cfd4e9;
}
.Login .footer a {
  margin-left: 8px;
  color: #4361d8;
  font-weight: bold;
}
.Login-container {
  width: 100%;
}
.Login-container input {
  width: 100%;
  padding: 11px 18px;
  margin-bottom: 12px;
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
.Login-container button {
  width: 66%;
  background: #4361d8 !important;
  color: #fff !important;
  border-radius: 6px;
  padding: 12px;
  cursor: pointer;
  margin-bottom: 12px;
  border: none;
  text-align: center;
  display: block !important;
  margin: 0 auto !important;
}
.Login-container .login-checkbox {
  margin-top: 30px !important;
  margin-bottom: 35px !important;
  text-align: center;
}
.Login-error {
  color: #dc2647;
}
header.Nav {
  height: 100vh;
  float: left;
  padding: 24px 18px 24px 19px;
  background: #f7f9fd;
  border-right: 1px solid #cfd4e9;
  position: fixed;
  z-index: 100;
  margin: 0;
  overflow: auto;
  width: 224px;
}
header.Nav ul {
  float: left;
  padding: 0;
  margin: 0;
}
header.Nav {
  float: left;
  list-style: none;
  clear: left;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 14px;
}
header.Nav .active {
  font-weight: bold;
}
header.Nav .active .icon:before {
  color: #4361d8;
}
header nav > a,
nav > div {
  float: left;
  clear: left;
  padding-bottom: 16px;
}
header.Nav a span:last-child {
  color: #161d39;
  float: left;
}
.Nav .icon {
  color: #c2c6da;
  margin-right: 10px;
  font-size: 20px;
  width: 20px;
  float: left;
  margin-top: -1px;
}
.Nav .icon-osf {
  font-size: 32px;
  margin-bottom: 24px;
  float: left;
}
.Nav-organizations {
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 3px;
  width: 194px;
  float: left;
  clear: left;
  margin-bottom: 40px;
  padding: 6px 10px;
  margin-left: -10px;
  cursor: pointer;
  background: transparent;
}
.alert-count {
  float: right !important;
  margin-left: 61px;
  background: #dc2647;
  border: 1px solid #000;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff !important;
}
.Nav .icon-coinsquare:before {
  color: #c2c6da;
  font-size: 20px;
  float: left;
  margin-right: 12px;
  width: 20px;
}
.Nav .user-options {
  width: 150px;
  background: transparent;
  text-align: center;
}
.Nav-label {
  float: left;
  clear: left;
  font-size: 13px;
  color: #737996;
  margin-bottom: 18px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 2.78571px;
  text-transform: uppercase;
}
.Nav nav {
  clear: left;
  float: left;
  margin-bottom: 24px;
}
.Content {
  display: flex;
  flex-wrap: wrap;
}
.Content-wrapper {
  flex-grow: 1;
}
.Content-head {
  float: left;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
}
.Content-body {
  float: left;
  clear: left;
  width: 100%;
}
.Order-entry {
  padding: 10px 20px;
  background-color: #ceeaff;
  border: 1px solid #6fa4cc;
  float: left;
  clear: left;
  width: 278px;
}
.Order-field {
  float: left;
  clear: left;
  margin-bottom: 12px;
  width: 100%;
}
.Order-field.Has-Label {
  margin: 14px 0;
}
.Order-field input[type="text"] {
  font-size: 14px;
  padding: 6px;
  width: 180px;
  border: 1px solid rgba(0,0,0,0.2);
}
.Order-field label {
  font-size: 12px;
  margin-left: 4px;
}
.Input-label {
  font-size: 11px;
  color: #3b4650;
  text-transform: uppercase;
}
.Order-field .Input-label {
  position: absolute;
  margin-top: -18px;
}
.Order-entry {
  margin-right: 22px;
  margin-bottom: 22px;
}
.Order-block {
  float: left;
}
.Order-results {
  float: left;
  clear: none;
  margin-right: 22px;
  padding-right: 22px;
  border-right: 1px solid #eaeaea;
}
.Order-results label {
  font-size: 12px;
  opacity: 0.8;
  float: left;
  margin-bottom: 4px;
}
.Order-results .value {
  font-weight: bold;
  font-size: 20px;
  clear: left;
}
.Order-entry select {
  float: right;
  padding: 6px;
  margin-left: 6px;
  border: none;
  background: #a9c4de;
  color: #183c5f;
  font-weight: bold;
  width: 50px;
  height: 30px;
  font-size: 12px;
}
.Order-entry select option {
  padding: 2px 0;
  border-bottom: 1px dashed #183c5f;
}
.Order-entry button {
  width: 100%;
  padding: 12px 0;
  float: left;
  clear: left;
  cursor: pointer;
  margin-bottom: 12px;
}
.Order-entry button:last-of-type {
  margin-bottom: 0;
}
.Timer-block :last-child {
  margin-left: 12px;
  font-weight: bold;
}
.react-json-view {
  margin: 10px 0;
  clear: left;
  float: left;
  text-align: left;
}
button.Background-blue {
  background: #193c5e;
  color: rgba(255,255,255,0.9);
  border: 1px solid #051c31;
}
.Button-swap {
  text-align: left;
  width: 100%;
  margin-top: -10px;
  float: left;
  cursor: pointer;
}
.Msg-client {
  padding-left: 6px;
  padding-bottom: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
}
.Chart-legend {
  float: left;
  width: 100%;
  padding-bottom: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid #eaeaea;
}
.Legend-item {
  position: relative;
  font-size: 14px;
  float: left;
  clear: left;
  margin: 4px 0;
  width: 100%;
  background-color: #f0f0f0;
}
.Legend-item .Legend-color {
  float: left;
  width: 12px;
  height: 18px;
  clear: left;
  opacity: 0.6;
}
.Legend-item .Legend-description {
  position: absolute;
  width: 100%;
  left: 0;
}
.Legend-item .Legend-value {
  margin-right: 6px;
  font-weight: 600;
  float: right;
}
.Legend-item .Legend-text {
  float: left;
  margin-left: 6px;
}
.priceRising {
  color: #0ead6a;
}
.priceFalling {
  color: #dc2647;
}
.Tag-currency {
  margin-left: 6px;
  font-size: 12px;
}
.otc-index {
  padding-top: 23px;
}
.otc-index .otc-block {
  float: left;
  padding-bottom: 40px;
}
.otc-index .otc-block .value {
  font-size: 24px;
  font-weight: bold;
  padding: 8px 0;
}
.otc-index .otc-tab .ui.grid {
  margin-right: 0;
}
.tabs {
  margin-bottom: 32px;
  border-bottom: 1px solid #cfd4e9;
  padding-left: 68px;
  display: flex;
  align-items: normal;
  flex-direction: row;
}
.tabs .tab {
  float: left;
  margin: 0 40px;
  padding: 0 4px;
  padding-bottom: 12px;
  cursor: pointer;
  color: #161d39;
}
.tabs .tab span {
  color: #737996;
}
.tabs .tab:first-child {
  margin-left: 0;
}
.tabs .tab.active {
  border-bottom: 3px solid #4361d8;
  font-size: 16px;
  font-weight: 700;
}
.balances .body {
  clear: left;
  padding-left: 68px;
  padding-right: 68px;
  overflow-x: auto;
}
.balances .body .title {
  font-size: 28px;
  float: left;
}
.balances .body .controls {
  float: right;
  margin-bottom: 24px;
}
.balances .body .controls .control {
  float: right;
  margin-right: 30px;
  border-style: none;
  border: 2px solid #cfd4e9;
  border-radius: 6px;
  height: 44px;
}
.balances .body .controls input {
  padding: 8px;
}
.balances .body .controls input ::placeholder {
  color: #737996;
}
.balances .body .controls button {
  float: right;
  background-color: #4361d8;
  color: #fff;
  padding: 14px 29px;
}
.balances table {
  border-right: none !important;
  border-left: none !important;
}
.balances table tr th {
  color: #737996 !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
}
.balances table tr .counterparty {
  font-size: 22px;
  font-weight: 700;
  color: #161d39;
}
.balances table td {
  font-size: 15px;
  padding-left: 0 !important;
  text-transform: capitalize;
}
.balances .stats-widget {
  display: flex;
  padding-left: 68px;
  margin-bottom: 42px;
  color: #161d39;
  overflow-x: auto;
}
.balances .stats-widget .value {
  font-size: 22px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.balances .stats-widget .value i {
  margin-right: 10px;
  font-size: 47px;
}
.balances .stats-widget .block-box {
  min-width: 182px;
  margin-right: 16px;
}
.balances .stats-widget .block-box .titleBlock {
  padding-left: 19px;
  margin-top: 19px;
  line-height: 30px;
  margin-bottom: 25px;
}
.balances .stats-widget .block-box .settlement,
.balances .stats-widget .block-box .financing {
  font-size: 25px;
  padding-left: 19px;
  padding-right: 19px;
  margin-bottom: 17px;
  line-height: 30px;
  word-wrap: break-word;
}
.balances .stats-widget .block-box .settlement span,
.balances .stats-widget .block-box .financing span {
  font-size: 12px;
  color: #737996;
  line-height: 14px;
  text-transform: uppercase;
}
.commitments {
  padding-left: 68px;
  padding-right: 68px;
}
.commitments .title {
  font-size: 28px;
  float: left;
  margin-top: 17px;
}
.commitments .controls {
  float: right;
  margin-bottom: 24px;
}
.commitments .controls .ui.selection.dropdown {
  border: 2px solid #cfd4e9;
  min-width: 309px;
}
.commitments .controls div {
  margin-right: 16px;
}
.commitments table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.commitments table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.commitments table td {
  font-size: 15px;
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.commitments table .ellipsis {
  color: #c2c6da;
  visibility: hidden;
}
.commitments table tbody tr:hover .ellipsis {
  visibility: visible;
}
.commitments table .tradeID {
  color: #737996;
  text-decoration-line: underline;
}
.payments {
  padding-left: 68px;
  padding-right: 68px;
}
.payments .title {
  font-size: 28px;
  float: left;
  margin-top: 17px;
}
.payments .controls {
  float: right;
  margin-bottom: 24px;
}
.payments .controls .ui.selection.dropdown {
  border: 2px solid #cfd4e9;
  min-width: 309px;
}
.payments .controls div {
  margin-right: 16px;
}
.payments table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.payments table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.payments table td {
  font-size: 15px;
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.payments table .clipText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 295px;
}
.payments table .transactionID {
  color: #737996;
  text-decoration-line: underline;
}
.payments table .ellipsis {
  color: #c2c6da;
  visibility: hidden;
}
.payments table tbody tr:hover .ellipsis {
  visibility: visible;
}
.row textarea {
  width: 100%;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  display: none;
  cursor: pointer;
  width: 120px;
  background-color: #151924;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  margin-top: 12px !important;
  margin-left: -18px !important;
  padding: 15px 0 15px 20px !important;
  position: absolute;
  z-index: 2;
  opacity: 0;
}
.tooltip .tooltiptext.otc {
  margin-left: -56px !important;
}
.tooltip .tooltiptext.otc::after {
  top: -40%;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: -25%;
  left: 50%;
  margin-left: -10px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #151924 transparent;
}
.tooltip:hover .tooltiptext {
  display: block;
  opacity: 1;
}
.ui.popup {
  border-radius: 0;
}
.ui.popup .content {
  white-space: nowrap;
  line-height: 28px;
  font-size: 15px;
  color: #c6cdde;
  font-weight: 700;
}
.ui.selection.dropdown {
  line-height: 18px !important;
  height: 44px;
}
.ui.selection.dropdown>.delete.icon,
.ui.selection.dropdown>.dropdown.icon,
.ui.selection.dropdown>.search.icon {
  line-height: 18px !important;
}
.ui.selection.dropdown>.menu.transition>.item,
.divider.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 460px;
}
.positions-index {
  padding-top: 23px;
}
.positions-index .positions-tab .ui.grid {
  margin-right: 0;
}
.positions-index .positions {
  padding-left: 68px;
  padding-right: 68px;
}
.positions-index .positions .title {
  font-weight: 400;
  font-size: 28px;
  float: left;
  margin-top: 14px;
}
.positions-index .positions .controls {
  float: right;
  margin-bottom: 17px;
}
.positions-index .positions .controls .ui.selection.dropdown {
  border: 2px solid #cfd4e9;
  min-width: 197px;
}
.positions-index .positions .controls div {
  margin-right: 16px;
}
.positions-index .positions table {
  border-right: none !important;
  border-left: none !important;
  color: #161d39 !important;
}
.positions-index .positions table th {
  color: #737996 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.positions-index .positions table td {
  font-size: 15px;
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.positions-index .positions table td:nth-last-child(2) {
  font-weight: 700;
}
.positions-index .positions table td:last-child {
  padding-right: 0;
}
.positions-index .positions table td:first-child span {
  font-size: 12px;
  line-height: 18px;
  color: #737996;
}
.quotes {
  padding-left: 68px;
}
.quotes button {
  color: #fff;
}
.quotes button:hover {
  background: #152c8a;
}
.quotes button:active {
  background: #12287e;
}
.quotes button.expired {
  cursor: not-allowed;
  opacity: 0.3;
}
.quotes .head-wrapper {
  right: 0;
  top: 0;
  width: 360px;
  background: #4361d8;
  height: 100%;
  color: #fff;
  padding: 68px;
  height: 100vh;
  position: fixed;
  box-sizing: initial;
}
.quotes .head-wrapper .head-content {
  position: relative;
}
.quotes .head-wrapper .head-content .placeholder {
  position: absolute;
  color: #000;
  font-weight: 600;
  left: 24px;
  top: 16px;
  font-size: 18px;
}
.quotes .tab-container {
  border-bottom: 2px solid #fff;
  width: 100%;
  float: left;
  margin-bottom: 18px;
}
.quotes .tab {
  float: left;
  padding: 10px 18px;
  opacity: 0.8;
  cursor: pointer;
}
.quotes .tab.selected {
  border-bottom: 3px solid #fff;
  opacity: 1;
  font-weight: 600;
}
.quotes .input {
  width: 100%;
  padding: 16px 14px;
  margin-bottom: 18px;
  background: #f0f3ff;
  border: 1px solid #1a2a6c;
  box-sizing: border-box;
  box-shadow: inset 0px 2px 2px rgba(0,0,0,0.25);
  border-radius: 6px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  padding-right: 44px;
}
.quotes .input::placeholder {
  color: #6e7dbb;
  font-weight: 300;
}
.quotes .input-error {
  background: #1a2243;
  position: absolute;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  bottom: -24px;
  z-index: 1000;
  right: 50%;
  border-radius: 5px;
  box-shadow: 0px 15px 25px rgba(30,46,109,0.6);
}
.quotes .input-error::after {
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #1a2243;
  right: 50%;
  margin-right: -3px;
  margin-top: -32px;
}
.quotes .select {
  position: relative;
  clear: left;
}
.quotes .select::after {
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #1f327d;
  right: 18px;
  margin-top: -48px;
}
.quotes .double-input input {
  border-radius: 6px 0 0 6px;
  width: 246px;
  border-right: none;
}
.quotes .double-input select {
  position: absolute;
  width: 142px;
  border-radius: 0;
  border-left: 1px solid #6e7dbb;
  border-radius: 0 6px 6px 0;
  right: 0;
}
.quotes .double-input [class*='icon-'] {
  position: absolute;
  right: 100px;
  top: 13px;
  font-size: 28px;
  z-index: 1;
  pointer-events: none;
}
.quotes .execute {
  background: #12287e;
  border-radius: 6px;
  width: 100%;
  padding: 14px;
  cursor: pointer;
  box-sizing: border-box;
}
.quotes .execute.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.quotes .execute:active {
  background: #0e2372;
}
.quotes .block-wrapper {
  float: left;
  clear: left;
}
.quotes .block-wrapper.disabled .top {
  opacity: 0.5;
}
.quotes .block-wrapper .block-body {
  width: 818px;
  padding: 24px;
  float: left;
  clear: left;
  border: 1px solid #cfd4e9;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 24px;
}
.quotes .block-wrapper .block-body .execute {
  float: right;
  width: 275px;
}
.quotes .block-wrapper .block-body .block-container {
  float: left;
  width: 100%;
}
.quotes .block-wrapper .block-body .block-container.top {
  display: flex;
}
.quotes .block-wrapper .block-body .block-container.top .block-field {
  flex: auto;
  padding: 12px 24px;
  border-right: 1px solid #cfd4e9;
  text-align: center;
}
.quotes .block-wrapper .block-body .block-container.top .block-field:first-child {
  text-align: left;
}
.quotes .block-wrapper .block-body .block-container.top .block-field .block-field-content {
  display: inline-block;
  text-align: left;
}
.quotes .block-wrapper .block-body .block-container.top .block-field:last-child {
  border-right: none;
}
.quotes .block-wrapper .block-body .block-container.bottom {
  clear: left;
  padding-top: 24px;
}
.quotes .block-wrapper .block-body .block-container .label {
  color: #161d39;
}
.quotes .block-wrapper .block-body .block-container h4 {
  font-size: 30px;
  margin: 12px 0;
}
.quotes .block-wrapper .block-body .block-container .block-timer-wrapper {
  padding: 0 24px;
}
.quotes .block-wrapper .block-body .block-container .block-timer-wrapper.taken .block-timer {
  padding-left: 34px;
}
.quotes .block-wrapper .block-body .block-container .block-timer-container {
  width: 360px;
  float: left;
  white-space: nowrap;
}
.quotes .block-wrapper .block-body .block-container .block-timer-container .block-timer {
  padding-bottom: 12px;
  position: relative;
}
.quotes .block-wrapper .block-body .block-container .block-bar-container {
  background: #f7f7f7;
  box-shadow: inset 0px 2px 2px rgba(0,0,0,0.1);
  width: 100%;
  height: 10px;
  float: left;
  border-radius: 5px;
}
.quotes .block-wrapper .block-body .block-container .block-bar {
  background: #4361d8;
  height: 10px;
  width: 100%;
  border-radius: 5px;
  -webkit-transition: width 0.1s ease;
  -o-transition: width 0.1 ease;
  transition: width 0.1 ease;
}
.quotes .block-wrapper .block-head {
  padding: 24px;
  border: 1px solid #cfd4e9;
  border-bottom: none;
  width: 818px;
  border-radius: 8px 8px 0px 0px;
  float: left;
  background: #f9f9f9;
}
.quotes .block-wrapper .block-head .text {
  font-size: 20px;
}
.quotes .block-wrapper .block-head .text.left {
  float: left;
  padding-left: 24px;
}
.quotes .block-wrapper .block-head .text.right {
  float: right;
  opacity: 0.7;
  padding-right: 24px;
}
.quotes .success-checkmark:after {
  content: '✔';
  position: absolute;
  left: 0;
  padding: 0 4px;
  text-align: center;
  border: 2px solid #4361d8;
  color: #4361d8;
  border-radius: 50%;
  margin-top: -2px;
}
.Reconiliations table td {
  font-size: 15px;
  text-transform: capitalize;
}
.Reconiliations table td .circle {
  width: 13px;
  height: 13px;
  background: #0ead6a;
  border-radius: 20px;
  float: left;
  margin: 3px;
  margin-right: 8px;
}
.Reconiliations table td:first-child > div:last-child {
  margin-top: 19px;
  float: left;
  margin-left: 8px;
  text-transform: capitalize;
}
.Reconiliations .iconWrapper {
  float: left;
}
.Reconiliations .iconWrapper .icon {
  background: #f7f9fd;
  text-align: center;
  padding: 3px;
  border-radius: 30px;
  margin-right: 0 !important;
  padding: 17px;
  display: block;
  font-size: 24px;
}
.Reconiliations .iconWrapper .icon:before {
  margin-right: 0 !important;
}
.Reconiliations .Accounts-block {
  display: flex;
  flex-wrap: wrap;
  padding-left: 68px;
}
.Reconiliations .Accounts-block .block-box {
  width: 188px;
  margin-right: 16px;
}
.Reconiliations .Accounts-block .block-box .value {
  clear: left;
  float: left;
  margin-top: 16px;
}
.Reconiliations .titleBlock .title {
  color: #737996;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 16px;
  float: left;
  clear: left;
}
.Reconiliations .titleBlock .change {
  clear: left;
  text-align: center;
  padding: 4px 8px;
  border-radius: 3px;
}
.Reconiliations .body {
  clear: left;
  padding-left: 68px;
  padding-right: 68px;
}
.Reconiliations .body .title {
  font-size: 28px;
  float: left;
}
.Reconiliations .body .controls {
  float: right;
  margin-bottom: 24px;
}
.Reconiliations .body .controls .control {
  float: right;
  margin-right: 30px;
  border-style: none;
  border: 2px solid #cfd4e9;
  border-radius: 6px;
  height: 44px;
}
.Reconiliations .body .controls input {
  padding: 8px;
}
.Reconiliations .body .controls input ::placeholder {
  color: #737996;
}
.Reconiliations .body .controls button {
  float: right;
  background-color: #4361d8;
  color: #fff;
  padding: 14px 29px;
}
.Ticker {
  padding: 24px 8px;
  border-bottom: 1px solid #cfd4e9;
  margin-bottom: 32px;
  padding-left: 68px;
}
.Ticker .chg {
  font-size: 10px;
  padding: 0 4px;
}
.Ticker .block {
  margin-right: 68px;
  text-transform: capitalize;
}
.Ticker .block:last-child {
  margin-right: 0;
}
.wallet-details {
  padding: 0px;
  height: fit-content;
}
.wallet-details-header {
  padding: 16px 24px;
}
.wallet-details-header {
  border-bottom: 1px solid rgba(34,36,38,0.15);
}
.wallet-details-title {
  color: #737996;
  font-size: 16px !important;
}
.wallet-details-subtitle {
  margin-top: 10px;
}
.wallet-details-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px !important;
}
.wallet-details-list-wrapper .list-name {
  color: #737996;
}
.wallet-details-list-wrapper .list-name >div {
  color: #161d39;
}
.wallet-details-list-wrapper .amount {
  font-size: 18px;
  font-weight: bold;
}
.ui.grid.wallets {
  padding-left: 53px;
  margin: 0;
}
.ui.grid.wallets .title {
  font-size: 27px;
  padding: 8px 0;
  text-transform: capitalize;
}
.ui.grid.wallets .row .wire-details {
  margin-top: 19px;
  border-bottom: 1px solid #dfe2e5;
  box-sizing: border-box;
  border-radius: 4px;
}
.ui.grid.wallets .row .wire-details .title {
  padding-left: 14px;
  font-size: 17px;
  text-transform: none;
  line-height: 32px;
}
.ui.grid.wallets .row .wire-details i {
  color: #0ead6a;
  font-size: 12px;
}
.ui.grid.wallets .row .wire-details span {
  margin-top: 0;
  font-size: 15px;
  padding-left: 14px;
  line-height: 25px;
  overflow-wrap: break-word;
}
.ui.grid.wallets .row .wire-details .verified {
  color: #0ead6a;
  font-size: 12px;
}
.ui.grid.wallets .row .wire-details .memo {
  font-size: 12px;
}
.ui.grid.wallets .row .wire-details .dropdown {
  color: #477ba1;
  padding-left: 20px;
}
.ui.grid.wallets .row .wire-details .dropdown i {
  padding-left: 0;
}
.ui.grid.wallets .row .wire-details .dropdown b {
  color: #161d39;
}
.ui.grid.wallets .ui.dimmer.active {
  min-height: 200px;
  max-width: 94%;
}
.ui.modal.active .actions>.button {
  margin-left: 0;
}
.button-circle {
  border-radius: 30px;
  padding: 5px 8px;
  box-shadow: 0px 2px 3px rgba(128,138,177,0.6);
  background: #4361d8;
  color: #fff;
  margin-left: 0;
  width: 24px;
  height: 24px;
  padding: 1px;
  float: inherit;
  text-align: center;
  cursor: pointer;
}
.button-circle:hover {
  box-shadow: 0px 2px 3px rgba(128,138,177,0.95);
}
.button-rectangle {
  padding: 14px;
  background: #4361d8;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 6px;
  float: inherit;
  font-size: 14px;
  box-shadow: 0px 2px 3px rgba(128,138,177,0.2);
  text-align: center;
  line-height: 16px;
}
.button-rectangle:hover {
  background: #506fe9;
}
.button-rectangle:active {
  background: #3b59d0;
}
.button-rectangle.delete {
  background: #dc2647;
}
.FormModal header {
  margin-top: 33px;
  padding-bottom: 26px;
  margin-left: 118px;
  margin-right: 118px;
  font-size: 27px;
  font-weight: 400;
  border-bottom: 1px solid #cfd4e9;
}
.FormModal header span {
  position: absolute;
  top: 18px;
  right: 18px;
  font-size: 15px;
  color: #737996;
  cursor: pointer;
}
.FormModal .description .column:first-child {
  margin-left: 15px;
  margin-right: 11px;
}
.FormModal .description .column .row {
  padding: 23px 0 0 0;
}
.FormModal .description .column .row .column {
  margin-left: 0;
}
.FormModal .description .column .row input {
  height: 44px;
}
.FormModal .description .column .row label {
  font-size: 15px;
  font-weight: bold;
  line-height: 36px;
}
.FormModal .description .column .row:last-child {
  margin-bottom: 41px;
  margin-top: 41px;
  padding-top: 0px;
  margin-right: 0px;
}
.FormModal .description .column .row .transfer-fee .row {
  padding-top: 14px;
}
.FormModal .description .column .row .transfer-fee .row .column {
  padding-right: 5px;
}
.FormModal .description .column .row .transfer-fee .column {
  margin: 0;
}
.FormModal .description .column .row .notes {
  margin-right: 0px;
}
.FormModal .orderSummary {
  border-top: 1px solid #cfd4e9;
  border-bottom: 1px solid #cfd4e9;
  padding: 16px 0;
  margin: 32px 0;
  margin-top: 16px;
}
.FormModal .orderSummary > div {
  font-size: 15px;
  line-height: 25px;
}
.FormModal .orderSummary label {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2.78571px;
  color: #737996;
}
.form {
  font-size: 15px;
}
.form .row {
  float: left;
  clear: left;
  width: 100%;
  margin-bottom: 16px;
}
.form .row input {
  height: 44px;
}
.form .row label {
  font-size: 15px;
  font-weight: bold;
  line-height: 36px;
}
.form .row:last-child {
  margin-bottom: 0;
}
.hide {
  display: none;
}
.leftSidebar {
  height: 100%;
  display: flex;
  margin-top: -32px;
}
.leftSidebar .leftColumn {
  width: 29.2%;
  height: 100vh;
  border-right: 1px solid #cfd4e9;
}
.leftSidebar .rightColumn {
  padding: 18px 36px;
  flex: 1;
}
table tr td {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
table tr.expandedRow {
  background: #f7f9fd;
  pointer-events: none;
}
table tr.expandedRow td:first-child {
  border-left: 2px solid #4361d8;
}
table tr.expandedRow.header {
  box-shadow: inset 0px 2px 2px rgba(184,186,191,0.25);
}
table tr.expandedRow.header td {
  padding: 0.92857143em 0.78571429em !important;
  font-size: 13px !important;
  color: #737996 !important;
}
button,
input,
select {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  border: none;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none /* get rid of default appearance for IE8, 9 and 10*/;
}
.ui.page.modals.dimmer {
  background: rgba(67,97,216,0.8) !important;
}
.green {
  color: #0ead6a;
}
.red {
  color: #dc2647;
}
.lightGreen {
  color: #e7f7f0;
}
.lightRed {
  color: #fbe9ed;
}
.lightGreenBg {
  background: #e7f7f0;
}
.lightRedBg {
  background: #fbe9ed;
}
.floatRight {
  float: right;
}
.floatLeft {
  float: left;
}
.noPaddingTop {
  padding-top: 0px !important;
}
.noPaddingLeft {
  padding-left: 0px !important;
}
.noPaddingBottom {
  padding-bottom: 0px !important;
}
.noPadding {
  padding: 0px !important;
}
.noMarginLeft {
  margin-left: 0px !important;
}
.noMargin {
  margin: 0 !important;
}
.boldText {
  font-weight: 700;
}
.capitalize {
  text-transform: capitalize !important;
}
.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #4361d8 !important;
  font-size: 15px;
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  height: 44px;
  line-height: 18px;
  text-align: center;
  border-radius: 6px;
}
.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background: #506fe9 !important;
}
.ui.primary.button:active,
.ui.primary.buttons .button:active {
  background: #3b59d0;
}
.ui.primary.button.disabled,
.ui.primary.buttons .button.disabled {
  opacity: 0.2;
}
.ui.basic.button,
.ui.basic.buttons .button {
  font-size: 15px;
  font-family: Lato;
  font-style: normal;
  color: #737996;
  height: 44px;
  line-height: 15px;
  text-align: center;
  border-radius: 6px !important;
  border: 2px solid #cfd4e9;
}
.ui.basic.button.disabled,
.ui.basic.buttons .button.disabled {
  opacity: 0.2;
}
input[type="date"],
input[type="number"],
input[type="text"],
input[type="email"],
input[type="tel"] {
  border: 1px solid #cfd4e9;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
}
.ui.modal {
  padding: 18px 0;
}
.ui.modal >.header {
  font-weight: 300 !important;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
.text-title {
  font-size: 15px;
  font-weight: 700;
  color: #161d39;
}
.App-body {
  font-family: Lato;
  color: #161d39;
  position: fixed;
  padding-left: 224px;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.App-body .ui.grid.page-wrapper {
  margin: 0;
}
.App-body .ui.grid header {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cfd4e9;
}
.App-body .Dashboard-block {
  border: 1px solid #cfd4e9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 15px;
  margin-right: 11px;
}
.App-body .Dashboard-block span .value {
  margin-left: 9px;
}
.App-body .Dashboard-block header {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.App-body .Dashboard-block section {
  padding-top: 0 !important;
  padding-bottom: 20px;
}
.App-body .Dashboard-block .titleBlock {
  padding: 16px 24px;
  font-size: 18px;
}
.App-body .Dashboard-block .Dashboard-block-table {
  clear: left;
}
.App-body .Dashboard-block .Dashboard-block-table th {
  color: #737996;
  font-weight: 300;
  padding-top: 0 !important;
}
.App-body .Dashboard-block .Dashboard-block-table th,
.App-body .Dashboard-block .Dashboard-block-table td {
  border-bottom: 1px solid #cfd4e9;
  padding: 16px 24px;
}
.App-body .Dashboard-block .Dashboard-block-table tr:last-child td {
  border-bottom: 0;
}
.App-body .Dashboard-block .icon,
.App-body .Dashboard-block .icon:before {
  color: #c2c6da;
}
.App-body .hidden {
  display: none;
}
.App-body .pending-settlement-widget .titleBlock {
  padding-top: 27px;
  padding-bottom: 29px;
}
.App-body .pending-settlement-widget .pending-settlement-widget-table th,
.App-body .pending-settlement-widget .pending-settlement-widget-table td {
  padding-left: 21px;
  padding-right: 21px;
}
.App-body .account-widget {
  min-width: 37%;
}
.App-body .account-widget .titleBlock {
  padding-top: 28px;
  padding-bottom: 33px;
}
.App-body .account-widget .account-widget-table th,
.App-body .account-widget .account-widget-table td {
  padding: 21px;
}
